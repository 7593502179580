import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import { Tabs, Tab } from 'react-bootstrap';
import SvgIcon from 'react-icons-kit';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { wrench } from 'react-icons-kit/fa/wrench';
import { group } from 'react-icons-kit/fa/group';

import 'sweetalert2/dist/sweetalert2.min.css';
import { eraseCookie } from '../../../../cookie.js';
import { Check } from 'react-feather';
import { OrgDataContext } from '../../../../routes.js';
import configs from '../../../../config/urlConfigs.js';
import moment from 'moment';
import { CSVLink, CSVDownload } from 'react-csv';
import { aborted } from 'util';
import CheckBox from 'rc-checkbox';
import AddEditMemberModal from '../6-ManageTeam/AddEditMemberModal.js';
import GetParticipantModal from './GetParticipantModal.js';

const readCookie = require('../../../../cookie.js').readCookie;
const createCookie = require('../../../../cookie.js').createCookie;

let organisationDataContext = null;
export default class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// employeeAttendanceSetting
			enableGoodwillPoint:false,
			enableCertificateGenerationForFamily :false,
			enableParticipantsForTimeChange:false,
			enableExternalParticipants:false,
			// enableEmployeeAttendance: false,
			enableEmployeeDetailsConfirmation: false,
			enablePayrollGiving: false,
			enableMatchGiving: false,
			autoApproveFundraisingProjects: false,
			autoApproveVolunteerOpportunities: false,
			enableGiveBack: false,
			enableEmployeeQuestionnaire: false,
			questionnaire: { mandatory: false, selectedEmployeeQuestions: [] },
			questionnaireCopy: {},
			enablEmployeeQuestionnaireCopy: false,
			employeeQuestions: [],
			allowHistoricalEventCreation: false,
			enableReviewersForValidation: false,
			members: [],
			addMemberModalVisibility: false,
			error: [],
			emp_id: "",
			full_name: "",
			email: "",
			gender: "",
			department: "",
			phone: "",
			designation: "",
			status: "",
			office: "",
			offices: null,
			volunteerPointsPerMin: 1,
			givingPointsPerRupee: 1,
			questionnaireUpdated: false,
			limitGallerySize: false,
			galleryLimit: 0,
			individualLimit: 0,
			pledgesData :[],
			deleteadmin:false,
			removeadminids:[],
			editAdminModalVisibility: false,
			editAdminRow: null,
			showAddAdminModal:false,
			userData:JSON.parse(readCookie("userData"))
		};

		this.onSaveSettings = this.onSaveSettings.bind(this);
		this.showAddMemberModal = this.showAddMemberModal.bind(this);
		this.hideAddMemberModal = this.hideAddMemberModal.bind(this);
		this.updateGeneralChange = this.updateGeneralChange.bind(this);
		this.showRemoveMemberModal=this.showRemoveMemberModal.bind(this);
		this.hideRemoveMemberModal= this.hideRemoveMemberModal.bind(this)
		this.Removemember=this.Removemember.bind(this)
	}

	componentDidMount() {
		let query = '';
		query = '?role=admin';
		fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ members: data.data });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/list', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ offices: data.lists });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/get-company-preference', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (Object.keys(data.data).length) {
				this.setState({
					// enableEmployeeAttendance
					enableParticipantsForTimeChange :data.data.enableParticipantsForTimeChange,
					enableCertificateGenerationForFamily :data.data.enableCertificateGenerationForFamily,
					enableGoodwillPoint : data.data.enableGoodwillPoint,
					enableExternalParticipants:data.data.enableExternalParticipants,
					// enableEmployeeAttendance: data.data.enableEmployeeAttendance,
					enableEmployeeDetailsConfirmation: data.data.enableEmployeeDetailsConfirmation,
					allowHistoricalEventCreation: data.data.allowHistoricalEventCreation === undefined ? false : data.data.allowHistoricalEventCreation,
					enableReviewersForValidation: data.data.enableReviewersForValidation || false,
					enablePayrollGiving: data.data.enablePayrollGiving,
					enableMatchGiving: data.data.enableMatchGiving,
					autoApproveFundraisingProjects: data.data.autoApproveFundraisingProjects,
					enableGiveBack: data.data.enableGiveBack,
					enableEmployeeQuestionnaire: data.data.enableEmployeeQuestionnaire,
					questionnaire: data.data.questionnaire,
					//copy
					enablEmployeeQuestionnaireCopy: data.data.enableEmployeeQuestionnaire,
					questionnaireCopy: data.data.questionnaire,
					employeeQuestions: data.employeeQuestions,
					galleryLimit: data.data.galleryLimit ? data.data.galleryLimit : 0,
					individualLimit: data.data.individualLimit ? data.data.individualLimit : 0,
					volunteerPointsPerMin: parseInt(data.data.volunteerPointsPerMin),
					givingPointsPerRupee: parseInt(data.data.givingPointsPerRupee),
				});
			}
		}.bind(this));
		this.verifyQuestionnaireUpdate = this.verifyQuestionnaireUpdate.bind(this);


		function addActivityStatus(array) {
			const currentDate = new Date();
	  
			// Iterate through each element of the array
			array.forEach((item) => {
			  // Convert date string to Date object
			  const [day, month, year] = item.endsOn.split("/");
	  
			  // JavaScript Date object takes month as 0-indexed (January is 0, February is 1, etc.)
			  // So we need to subtract 1 from the month value
			  const itemDate = new Date(`${year}-${month}-${day}`);
	  
			  // Check if the date is active or inactive
			  item.active = itemDate >= currentDate ? true : false;
			});
	  
			return array;
		  }
		fetch(configs.BACKEND_API_URL + '/ee/pledges/all-pledges-admin' , {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
		const updatedArray =	addActivityStatus(data.pledges)
      this.setState({pledgesData :updatedArray})
		}.bind(this));
	}

	async verifyQuestionnaireUpdate() {

		this.state.questionnaire.selectedEmployeeQuestions.forEach((question, questionNumber) => {
			if (question.checked !== this.state.questionnaireCopy.selectedEmployeeQuestions[questionNumber]['checked']) {
				this.setState({ questionnaireUpdated: true });
				return;
			}
		});
	}

	updateGeneralChange(name, e) {
		if (name === 'volunteerPointsPerMin' || name === 'givingPointsPerRupee') {
			this.setState({ [name]: e.target.value });
		} else {
			this.setState({ [name]: !this.state[name] });
		}
	}

	async onSaveSettings() {
		let data = {};
		let self = this;
		// await this.verifyQuestionnaireUpdate();
		data.enableParticipantsForTimeChange = this.state.enableParticipantsForTimeChange;
		data.enableExternalParticipants = this.state.enableExternalParticipants;
		data.enableCertificateGenerationForFamily = this.state.enableCertificateGenerationForFamily;
		data.enableGoodwillPoint = this.state.enableGoodwillPoint;
		//  data.enableEmployeeAttendance = this.state.enableEmployeeAttendance;
		data.enableEmployeeDetailsConfirmation = this.state.enableEmployeeDetailsConfirmation;
		data.enableReviewersForValidation = this.state.enableReviewersForValidation;
		data.allowHistoricalEventCreation = this.state.allowHistoricalEventCreation;
		data.enablePayrollGiving = this.state.enablePayrollGiving;
		data.enableMatchGiving = this.state.enableMatchGiving;
		data.autoApproveFundraisingProjects = this.state.autoApproveFundraisingProjects;
		data.enableGiveBack = this.state.enableGiveBack;
		data.enableEmployeeQuestionnaire = this.state.enableEmployeeQuestionnaire;
		data.questionnaire = { ...this.state.questionnaire, selectedEmployeeQuestions: this.state.employeeQuestions };
		data.volunteerPointsPerMin = parseInt(this.state.volunteerPointsPerMin);
		data.givingPointsPerRupee = parseInt(this.state.givingPointsPerRupee);
		data.employeeQuestions = this.state.employeeQuestions;
		data.galleryLimit = parseInt(this.state.galleryLimit);
		data.individualLimit = parseInt(this.state.individualLimit);
		fetch(configs.BACKEND_API_URL + `/save-company-preference?questionnaireUpdated=${this.state.questionnaireUpdated}`, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function (response) {
			return response.json();
		}).then(function (res) {
			if (res.status === 'ok') {
				// Added to fix Cookie refresh bug =======Start=======>
				const userCookie = JSON.parse(readCookie('userData'));
				eraseCookie('userData');
				organisationDataContext.eePreferences.enableParticipantsForTimeChange = data.enableParticipantsForTimeChange;
				organisationDataContext.eePreferences.enableExternalParticipants = data.enableExternalParticipants;
				organisationDataContext.eePreferences.enableCertificateGenerationForFamily = data.enableCertificateGenerationForFamily;
				organisationDataContext.eePreferences.enableGoodwillPoint = data.enableGoodWillPoint;
				organisationDataContext.eePreferences.enableEmployeeDetailsConfirmation = data.enableEmployeeDetailsConfirmation;
				// organisationDataContext.eePreferences.enableEmployeeAttendance = data.enableEmployeeAttendance;
				organisationDataContext.eePreferences.enablePayrollGiving = data.enablePayrollGiving;
				organisationDataContext.eePreferences.enableMatchGiving = data.enableMatchGiving;
				organisationDataContext.eePreferences.autoApproveFundraisingProjects = data.autoApproveFundraisingProjects;
				organisationDataContext.eePreferences.autoApproveVolunteerOpportunities = data.autoApproveVolunteerOpportunities;
				organisationDataContext.eePreferences.enableGiveBack = data.enableGiveBack;
				organisationDataContext.eePreferences.enableEmployeeQuestionnaire = data.enableEmployeeQuestionnaire;
				organisationDataContext.eePreferences.enableGiveBack = data.galleryLimit
				organisationDataContext.eePreferences.enableEmployeeQuestionnaire = data.individualLimit
				delete userCookie.organisationData;
				delete userCookie.questionnairePreferences;
				delete userCookie.preference;
				createCookie('userData', JSON.stringify(userCookie), 7);
				// Added to fix Cookie refresh bug =======End=======>
				swal('Awesome!', 'Successfully saved your preferences.', 'success').then(() => {
					
					self.setState({ questionnaireUpdated: false, questionnaireCopy: JSON.parse(JSON.stringify(data['questionnaire'])) });
					let userData = JSON.parse(readCookie('userData'));
					organisationDataContext.eePreferences = { ...data, questionnaire: {} };
					organisationDataContext.eePreferences = { ...data, questionnaire: {} };
					delete userData.organisationData;
					delete userData.questionnairePreferences;
					delete userData.preference;
					createCookie('userData', JSON.stringify(userData), 7);
					window.location.reload();
				});
			}
		});

	}

	createCustomButtonGroup(props, onClick) {
		return (
			<div>
			</div>
		);
	}

	createCustomClearButton(onClick) {
		return (
			<span onClick={onClick} className="clear-table-search-container">
				<i className="clear-table-search fa fa-times"></i>
			</span>
		);
	}

	employeeId(cell, row) {
		if (cell === '') {
			return (<span></span>)
		} else {
			return (
				<a href={'/team-management/' + row.listId + '/' + row.id} title={cell}>{cell}</a>
			)
		}
	}

	fullName(cell, row) {
		return (
			<a href={'/team-management/' + row.listId + '/' + row.id} title={cell}>{cell}</a>
		)
	}

	email(cell, row) {
		return (
			<span title={cell}>{cell}</span>
		)
	}

	showRemoveMemberModal(row) {
		this.setState({
			deleteadmin:true,
			removeadminids:[]
		});
	}
	hideRemoveMemberModal(row) {
		this.setState({
			deleteadmin:false,
			removeadminids:[]
		});
	}

	showAddMemberModal(row) {
		this.setState({
			addMemberModalVisibility: true,
			full_name: '',
			emp_id: '',
			gender: '',
			department: '',
			phone: '',
			email: '',
			designation: '',
			status: '',
			error: []
		});
	}

	hideAddMemberModal() {
		this.setState({
			addMemberModalVisibility: false,
			full_name: '',
			emp_id: '',
			gender: '',
			department: '',
			phone: '',
			email: '',
			designation: '',
			status: '',
			error: []
		});
	}

	updateChange(name, required, e) {
		var error = this.state.error;
		var index = error.indexOf(name);
		if (required && e.target.value !== '' && index > -1) error.splice(index, 1);

		this.setState({ [name]: e.target.value, error });
	}

	updateChangeForChangingRole(name, required, e) {
		console.log(name,'name',e.target.value,'e');
		
		var error = this.state.error;
		var index = error.indexOf(name);
		// if (e.target && e.target.value !== '' && index > -1) error.splice(index, 1);
		
		// Only update role field in editAdminRow object
		if (name === 'role') {
			this.setState({ 
				editAdminRow: {
					...this.state.editAdminRow,
					role: e.target.value
				},
				// error 
			});
		}else if(name === 'status') {
			this.setState({ 
				editAdminRow: {
					...this.state.editAdminRow,
					status: e.target.value
				},
				// error 
			});
		}
	}


	updateQuestionnaireChange(name) {
		switch (name) {
			case 'mandatory': this.setState({
				questionnaire: {
					selectedEmployeeQuestions: this.state.employeeQuestions,
					mandatory: true
				}, enableEmployeeQuestionnaire: true
			});
				break;
			case 'optional': this.setState({
				questionnaire: {
					selectedEmployeeQuestions:
						this.state.employeeQuestions, mandatory: false
				}, enableEmployeeQuestionnaire: true
			});
				break;
			case 'disable': this.setState({
				questionnaire: {
					selectedEmployeeQuestions: [],
					mandatory: false
				}, enableEmployeeQuestionnaire: false
			});
				break;
			default: let employeeQuestions = this.state.employeeQuestions;
				employeeQuestions[name]['checked'] = !employeeQuestions[name]['checked'];
				this.setState({
					employeeQuestions, questionnaire: {
						...this.state.questionnaire,
						selectedEmployeeQuestions: employeeQuestions/*.filter(question=>question.checked)*/
					}
				})
		}
	}

	addMember() {
		var full_name = this.state.full_name;
		var emp_id = this.state.emp_id;
		var gender = this.state.gender;
		var department = this.state.department;
		var phone = this.state.phone;
		var email = this.state.email;
		var designation = this.state.designation;
		var status = this.state.status;
		var office = this.state.office;
		if (full_name !== '' && email !== '' && office !== '') {
			var data = {};
			data['fullName'] = full_name;
			data['employeeId'] = emp_id;
			data['gender'] = gender;
			data['department'] = department;
			data['mobileNo'] = phone;
			data['designation'] = designation;
			data['status'] = status;
			data['email'] = email;
			data['role']='admin'

			data['listId'] = this.props.match.params.listId;

			fetch(configs.BACKEND_API_URL + '/add-employees', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then((response) => response.json())
				.then(function (data) {
					this.hideAddMemberModal();
				}.bind(this));
		} else {
			var error = [];
			if (full_name === '') error.push('full_name');
			if (email === '') error.push('email');
			if (office === '') error.push('office');
			this.setState({ error });
		}
	}

	updateMember() {
		// Get data from editAdminRow instead of state
		const { editAdminRow } = this.state;
		
		if (editAdminRow) {
			const data = {
				fullName: editAdminRow.fullName,
				employeeId: editAdminRow.employeeId,
				gender: editAdminRow.gender,
				department: editAdminRow.department,
				mobileNo: editAdminRow.mobileNo,
				designation: editAdminRow.designationAtOrganisation,
				status: editAdminRow.status,
				role: editAdminRow.role,
				email: editAdminRow.email
			};
			console.log(data,'data');
			
			// Update the member
			fetch(`${configs.BACKEND_API_URL}/employee/${editAdminRow.id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error('Failed to update the employee');
					}
					return response.json();
				})
				.then(() => {
					swal('Successful!', 'You have updated the employee.', 'success');
					this.hideEditAdminModal();
					this.refreshAdminList(); // Refresh admin list
				})
				.catch((error) => {
					console.error('Error updating employee:', error);
					swal('Error!', 'Failed to update the employee.', 'error');
				});
		}
	}
	
	refreshAdminList() {
		const query = '?role=admin';
	
		// Fetch the updated admin list
		fetch(`${configs.BACKEND_API_URL}/get-employees${query}`, {
			method: 'GET',
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch the employee list');
				}
				return response.json();
			})
			.then((data) => {
				this.setState({ members: data.data });
			})
			.catch((error) => {
				console.error('Error fetching admin list:', error);
			});
	}
	
	addMemberAsAdmin(data){
		
		const editAdminRow = data;

		// Check if user is already an admin
		const existingAdmin = this.state.members.find(member => 
			member.employeeId === data.employeeId
		);

		if (existingAdmin) {
			swal({
				title: "User already an admin",
				text: "This employee is already an admin",
				type: "warning",
				showCancelButton: true,
				showConfirmButton: false,
				cancelButtonText: "Ok"
			});
			this.setState({showAddAdminModal: false});
			return;
		}
		
		if (editAdminRow) {
			const data = {
				fullName: editAdminRow.fullName,
				employeeId: editAdminRow.employeeId,
				gender: editAdminRow.gender,
				department: editAdminRow.department,
				mobileNo: editAdminRow.mobileNo,
				designation: editAdminRow.designationAtOrganisation,
				status: editAdminRow.status,
				role: 'admin',
				email: editAdminRow.email
			};
			console.log(data,'data');
			
			// Update the member
			fetch(`${configs.BACKEND_API_URL}/employee/${editAdminRow.id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error('Failed to update the employee');
					}
					return response.json();
				})
				.then(() => {
					swal('Successful!', 'You have added the employee as admin.', 'success');
					this.setState({showAddAdminModal:false})
					this.refreshAdminList(); // Refresh admin list
				})
				.catch((error) => {
					console.error('Error updating employee:', error);
					swal('Error!', 'Failed to update the employee.', 'error');
				});
		}
	}

	Removemember(){
		try {
			let empTempId=this.state.removeadminids
			 
			swal({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Delete!',
				cancelButtonText: 'No, Cancel!',
				confirmButtonClass: 'btn',
				cancelButtonClass: 'btn',
			}).then(function (result) {
				if (result) {
					fetch(configs.BACKEND_API_URL + '/delete-employee', {
						method: "POST",
						headers: {
							'Content-Type': 'application/json',
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
						body: JSON.stringify({ employeeIds: empTempId })
					}).then((response) => response.json())
						.then((data) => {
							if (data.status === 'ok') {
								this.setState({
									deleteadmin:false
								})
								let query = '';
								query = '?role=admin';
								 fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
									method: "GET",
									headers: {
										'Auth': JSON.parse(readCookie('access_token')).access_token
									},
								}).then(function (response) {
									return response.json();
								}).then(function (data) {
									this.setState({ members: data.data });
								}.bind(this));
							}})}})
		} catch (error) {
			console.log(error,'err');
		}
	}
	toggleAdminId(id) {
		this.setState(prevState => {
		  const { removeadminids } = prevState;
		  if (removeadminids.includes(id)) {
			// If the ID is already in the array, remove it
			return { removeadminids: removeadminids.filter(adminId => adminId !== id) };
		  } else {
			// If the ID is not in the array, add it
			return { removeadminids: [...removeadminids, id] };
		  }
		});
	  }
	hideEditAdminModal() {
		this.setState({
			editAdminModalVisibility: false,
			editAdminRow: null
		});
	}

	EditAdmin = (cell, row, extra) => {
		return (
			<Button 
				className="btn-sq btn-edit"
				onClick={() => this.showEditAdminModal(row)}
			>
				Edit
			</Button>
		);
	}

	showEditAdminModal(row){
		console.log(row,'row');
		this.setState({
			editAdminModalVisibility: true,
			editAdminRow: row
		});
	}

	

	render() {
		const options = {
			btnGroup: this.createCustomButtonGroup.bind(this),
			clearSearch: true,
			clearSearchBtn: this.createCustomClearButton,
			sizePerPage: 20,
			sizePerPageList: [20, 50, 100]
		};

		return (
		<OrgDataContext.Consumer>
				{(organisationData)=> {
					organisationDataContext = organisationData;
			return (<div className="ee-page-container settings-page">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Settings</h2>
					</Col>
				</Row>
				<Row>
					<Tabs defaultActiveKey={1} id="admin-settings-tab">
						<Tab eventKey={1} title={<div className="admin-settings-tab-header"><SvgIcon size={14} icon={wrench} /><span> General</span></div>}>
							<Col md={12} className="mt20">
								<form name="groupForm">

									<div class="form-group form-check checkbox-row">
										<a className={this.state.enableEmployeeDetailsConfirmation ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableEmployeeDetailsConfirmation')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-enableEmployeeDetailsConfirmation">
											Enable employee details confirmation and preference capturing
										</label>
									</div>

									{/* <div class="form-group form-check checkbox-row">
										<a className={this.state.enableEmployeeAttendance ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableEmployeeAttendance')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-EmployeeAttendance">
											Enable employee attendance validation/override
										</label>
									</div> */}

										<div class="form-group form-check checkbox-row">
										<a className={this.state.enableExternalParticipants ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableExternalParticipants')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-enableEmployeeDetailsConfirmation">
												Allow external participants
										</label>
									</div>
									<div class="form-group form-check checkbox-row">
										<a className={this.state.enableCertificateGenerationForFamily ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableCertificateGenerationForFamily')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-enableEmployeeDetailsConfirmation">
											Allow family/friends/outside participants for certificate generation.
										</label>
									</div>
									<div class="form-group form-check checkbox-row">
										<a className={this.state.enableParticipantsForTimeChange ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableParticipantsForTimeChange')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-enableParticipantsForTimeChange">
											Allow participants for change time in attendance.
										</label>
									</div>

									<div class="form-group form-check checkbox-row">
										<a className={this.state.enablePayrollGiving ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enablePayrollGiving')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-payroll-giving">
											Enable payroll giving
										</label>
									</div>

									<div class="form-group form-check checkbox-row">
										<a className={this.state.enableGiveBack ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableGiveBack')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-giveback">
											Enable Give Back Campaigns
										</label>
									</div>

									<div class="form-group form-check checkbox-row">
										<a className={this.state.enableMatchGiving ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableMatchGiving')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="enable-matching-giving">
											Enable matching giving
										</label>
									</div>

									<div class="form-group form-check checkbox-row">
										<a className={this.state.autoApproveFundraisingProjects ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'autoApproveFundraisingProjects')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="approve-fundraising">
											Auto-approve all fundraising projects
										</label>
									</div>

									<div class="form-group form-check checkbox-row">
										<a className={this.state.allowHistoricalEventCreation ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'allowHistoricalEventCreation')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="allow-historical-event">
											Allow Historical Event Creation
										</label>
									</div>
									
									<div class="form-group form-check checkbox-row">
										<a className={this.state.enableGoodwillPoint ? 'checkbox-active' : 'checkbox-inactive'}
											onClick={this.updateGeneralChange.bind(this, 'enableGoodwillPoint')}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
										<label class="form-check-label" style={{ margin: 10 }} for="allow-historical-event">
											Enable goodwill point
										</label>
									</div>
									{/* <div className="form-group">
										<input type="checkbox" id="add-reviewers" checked={this.state.enableReviewersForValidation} onChange={this.updateGeneralChange.bind(this, 'enableReviewersForValidation')} />
										<label className="checkbox-label" htmlFor="add-reviewers">
											Add Reviewers for volunteering hours validation
										</label>
									</div> */}

									{/* <div className="questionnaire">
										Onboarding Preference Questionnaire
										<div className="form-group">
											<div className="form-check radio">
												<input type="radio" className="radio questionnaire-radio" value="" id="mandatory-questionnaire" name="questionnaire" checked={this.state.enableEmployeeQuestionnaire && this.state.questionnaire.mandatory} onChange={this.updateQuestionnaireChange.bind(this, 'mandatory')} />
												<label htmlFor="mandatory-questionnaire">
													Make preference questionnaire mandatory
												</label>
											</div></div>
										<div className="form-check radio">
											<input type="radio" className="radio questionnaire-radio" value="" id="optional-questionnaire" name="questionnaire" checked={this.state.enableEmployeeQuestionnaire && !this.state.questionnaire.mandatory} onChange={this.updateQuestionnaireChange.bind(this, 'optional')} />
											<label htmlFor="optional-questionnaire">
												Make preference questionnaire optional/skippable
											</label>
										</div>
										<div className="form-check radio">
											<input type="radio" className="radio questionnaire-radio" value="" id="disable-questionnaire" name="questionnaire" checked={!this.state.enableEmployeeQuestionnaire && !this.state.questionnaire.mandatory} onChange={this.updateQuestionnaireChange.bind(this, 'disable')} />
											<label htmlFor="disable-questionnaire">
												Do not show preference questionnaires ever
											</label>
										</div>

										{this.state.enableEmployeeQuestionnaire &&
											(<div className="questionnaires">
												Select from the questions below.
												{this.state.employeeQuestions.map((question, index) => {
													return (<div className="form-group" key={index}>
														<input type="checkbox" id={"employee-question-" + index} 
														checked={question.checked} onChange={this.updateQuestionnaireChange.bind(this, index)} />
														<label className="checkbox-label" htmlFor={"employee-question-" + index}>
															{question.question}
														</label>
													</div>)
												})}
											</div>)}
									</div> */}
									<div className="form-group">
										<label className="checkbox-label" htmlFor="gallery-limit">
											Event-wise gallery image limit
										</label>
										<input type="number" className="form-control" id="gallery-limit" value={this.state.galleryLimit} placeholder="1" onChange={e => (e.target.value > -1 && e.target.value < 10000001) && this.setState({ galleryLimit: e.target.value })} style={{ width: 200 }} min={0} max={50} />
									</div>

									<div className="form-group">
										<label className="checkbox-label" htmlFor="gallery-limit">
											Individual gallery image limit
										</label>
										<input type="number" className="form-control" id="gallery-limit" value={this.state.individualLimit} placeholder="1" onChange={e => (e.target.value > -1 && e.target.value < 10000001) && this.setState({ individualLimit: e.target.value })} style={{ width: 200 }} min={0} max={5} />
									</div>
                               { this.state.enableGoodwillPoint ?<div className="form-group">
										<label className="checkbox-label" htmlFor="vol-points">
											Points / minute of Volunteering
										</label>
										<input type="number" className="form-control" id="vol-points" value={this.state.volunteerPointsPerMin} placeholder="1" onChange={this.updateGeneralChange.bind(this, 'volunteerPointsPerMin')} style={{ width: 200 }} min={0} />
									</div> : null}
									<div className="form-group">
										<label className="checkbox-label" htmlFor="give-points">
											Points / rupee Contributed
										</label>
										<input type="number" className="form-control" id="give-points" value={this.state.givingPointsPerRupee} placeholder="1" onChange={this.updateGeneralChange.bind(this, 'givingPointsPerRupee')} style={{ width: 200 }} min={0} />
									</div>

									<div className="form-group">
										<label className="checkbox-label" htmlFor="gallery-limit">
											PLEDGES
										 </label>
									</div>
									{this.state.pledgesData.map((data, index) => {
										 const transformedData = data.result.map(result => {
											const rowData = {
												hexaId: result.userId,
												employeeId:result.employeeId,
												name : result.fullName,
												department:result.department,
												email :result.email,
												mobile :result.mobileNo,
												alternateMobileNo : result.alternateMobileNumber,
												designation :result.designation,
												dateOfJoining :moment(result.dateOfJoining).format('DD-MM-YYYY'),
												businessUnit :result.businessUnit,
												ouName :result.ouName,
												reportingManagerName :result.reportingManagerName,
												gender:result.gender,
												reportingManagerDesignation :result.reportingManagerDesignation,
												reportingManagerEmail :result.reportingManagerEmail,
												location :result.location,
												city :result.city,
												region :result.region,
												source :result.source,
												pledgeSubmited:moment(result.updatedOn).format('DD-MM-YYYY HH:mm'), //result.createdOn,
												Responses: JSON.stringify(result.responses), // Flatten nested object into a string
											};
								
											// Add questions as additional columns
											data.Questions.forEach((question, index) => {
												const responseKey = `${index}`; // Assuming the response key follows the pattern "0-0", "1-1", etc.
												rowData[`Question ${responseKey}`] = result.responses[responseKey] ? 'Yes' : 'No';
											});
											
								
											return rowData;
										});
										  const headers = [
											{ label: 'Hexa Id', key: 'hexaId' },
											{label:"Employee Id",key:"employeeId"},
											{label :"Name",key :"name"},
											{label:"Department",key:"department"},
											{label:"Email",key:"email"},
											{label:"Mobile",key :"mobile"},
											{label :"Alternative Mobile Number",key :"alternateMobileNo"},
											{label :"Designation" ,key :"designation"},
											{label :"Pledge Submitted at" ,key :"pledgeSubmited"},
											{label :"Date of joining",key :"dateOfJoining"},
											{label:"Gender",key:"gender"},
											{label:"Business Unit",key : "businessUnit"},
											{label :"OU Name",key :"ouName"},
											{label : "Reporting Manager Name",key :"reportingManagerName"},
											{label :"Reporting Manager Designation",key :"reportingManagerDesignation"},
                                            {label : "Reporting Manager Email", key :"reportingManagerEmail"},
											{label : "Location",key : "location"},
											{label :"City",key :"city" },
											{label :"Region" , key :"region"},
											{label : "Source", key :"source"},

											
											
											...(data.Questions || []).map((question, index) => ({ label: question, key: `Question ${index}` }))
										];
										
								return	<div key={index} style={{marginBottom:20}}> 
								

								<>
								<p style={{fontWeight:"bold"}}>
								{data.title} {"("} {data.active ? "Active" : null} {") "}
								<CSVLink data={transformedData} headers={headers} filename={`${data.title}.csv`}>
								{data.result && data.result.length > 0 ? `( ${data.result.length} ${data.result.length === 1 ? "response" : "responses"} )` : null}</CSVLink>
								</p>
								<p>
								Launched on: {moment(data.launchedOn, "DD/MM/YYYY").format("D MMMM YYYY")}, 
								Ends on: {moment(data.endsOn, "DD/MM/YYYY").format("D MMMM YYYY")}
								</p>
							</>
							</div>
									})}

									<div className="form-group">
										<button type="button" className="btn btn-primary" onClick={this.onSaveSettings}>
											Save
										</button>
									</div>
								</form>
							</Col>
						</Tab>
						<Tab eventKey={2} title={<div className="admin-settings-tab-header"><SvgIcon size={14} icon={group} /><span> Administrators</span></div>}>
							<div className="note mb5 mt15 text-center">If you wish to add more administrators, please add them among the list of employees and upgrade their roles from there.</div>
							<div className="note mb10 mt5 text-center">To revoke admin access, downgrade their rights from the list of employees.</div>
							<div>
							</div>
							<div className="admin-settings-member-table" style={{position:'relative'}}>
								{console.log(this.state.userData,'this.state.userData')
								}
								<Button class="btn btn-outline-primary ml5 mr5" type="button" style={{position:'absolute',left:0,top:-8}} onClick={()=>{this.setState({showAddAdminModal:true})}}>Add Admin</Button>
								<BootstrapTable data={this.state.members} options={options} striped={true} hover={true} search searchPlaceholder="Search by Name/Email/Office">
									<TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
									<TableHeaderColumn dataField='employeeId' searchable dataFormat={this.employeeId.bind(this)}>Employee ID</TableHeaderColumn>
									<TableHeaderColumn width='160' dataField='fullName' searchable dataFormat={this.fullName.bind(this)}>Full Name</TableHeaderColumn>
									<TableHeaderColumn width='200' dataField='email' searchable dataFormat={this.email.bind(this)}>Email</TableHeaderColumn>
									<TableHeaderColumn width='100' dataField='mobileNo' searchable>Mobile</TableHeaderColumn>
									<TableHeaderColumn dataField='department' searchable>Department</TableHeaderColumn>
									<TableHeaderColumn dataField='designationAtOrganisation' searchable>Designation</TableHeaderColumn>
									<TableHeaderColumn width='80' dataField='status' searchable>Status</TableHeaderColumn>
									<TableHeaderColumn width='80' dataFormat={this.EditAdmin}
                                formatExtraData={this.props} >Edit</TableHeaderColumn>
								{/* {this.state.userData && (this.state.userData._id== "66b1b73a6a8b233d5a2883c2"|| this.state.userData._id== "63c6311250966e3d4563550f")&&<TableHeaderColumn width='80' dataFormat={this.EditAdmin}
                                formatExtraData={this.props} >Edit</TableHeaderColumn>} */}
								</BootstrapTable>
							</div>

							{this.state.addMemberModalVisibility ? (
								<Modal bsSize="large" className="add-member-modal" show={this.state.addMemberModalVisibility} onHide={this.hideAddMemberModal}>
									<Modal.Header>
										<Modal.Title className="modal-header-custom">
											Add Admin
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Row>
											<Col className="margin-col-50" md={12}>
												<form id="edit-org-details-modal-form"
													// ref="vForm"
												>

													<Col className="form-group" md={4}>
														<label className="control-label">Employee ID: </label>
														<input type="text" className="form-control" id="emp_id" name="emp_id" onChange={this.updateChange.bind(this, "emp_id", false)}
															placeholder="Employee ID" required defaultValue={this.state.emp_id} />
														{this.state.error.indexOf('emp_id') > -1 ? (
															<span className="red-color">Incorrect Employee ID!</span>
														) : (null)}
													</Col>

													<Col className="form-group" md={4}>
														<label className="control-label is-imp">Full Name: </label>
														<input type="text" className="form-control" id="full_name" name="full_name" onChange={this.updateChange.bind(this, "full_name", true)}
															placeholder="Full Name" required defaultValue={this.state.full_name} />
														{this.state.error.indexOf('full_name') > -1 ? (
															<span className="red-color">Incorrect Name!</span>
														) : (null)}
													</Col>

													<Col className="form-group" md={4}>
														<label className="control-label is-imp">Email ID: </label>
														<input type="email" className="form-control" id="email" name="email" onChange={this.updateChange.bind(this, "email", true)}
															placeholder="Email ID" required defaultValue={this.state.email} disabled={this.state.editMember ? true : false} />
														{this.state.error.indexOf('email') > -1 ? (
															<span className="red-color">Incorrect Email ID!</span>
														) : (null)}
													</Col>

													<Col className="form-group" md={4}>
														<label className="control-label">Gender: </label>
														<select className="form-control" id="gender" name="gender" onChange={this.updateChange.bind(this, "gender", false)} required value={this.state.gender}>
															<option value="" disabled>Select Gender</option>
															<option value="Male">Male</option>
															<option value="Female">Female</option>
															<option value="Others">Others</option>
														</select>
														{this.state.error.indexOf('gender') > -1 ? (
															<span className="red-color">Incorrect Gender!</span>
														) : (null)}
													</Col>

													{this.state.offices !== null ? (
														<Col className="form-group" md={4}>
															<label className="control-label is-imp">Office: </label>
															<select className="form-control" id="office" name="office" onChange={this.updateChange.bind(this, "office", true)} required value={this.state.office}>
																<option value="" disabled>Select Office</option>
																{this.state.offices.map(function (office, index) {
																	return (
																		<option value={office.id}>{office.name}</option>
																	)
																})}
															</select>
															{this.state.error.indexOf('office') > -1 ? (
																<span className="red-color">Incorrect Office!</span>
															) : (null)}
														</Col>
													) : (null)}

													<Col className="form-group" md={4}>
														<label className="control-label">Department: </label>
														<input type="text" className="form-control" id="department" name="department" onChange={this.updateChange.bind(this, "department", false)}
															placeholder="Department" required defaultValue={this.state.department} />
														{this.state.error.indexOf('department') > -1 ? (
															<span className="red-color">Incorrect Department!</span>
														) : (null)}
													</Col>

													<Col className="form-group" md={4}>
														<label className="control-label">Phone: </label>
														<input type="mob" className="form-control" id="phone" name="phone" onChange={this.updateChange.bind(this, "phone", false)}
															placeholder="Phone" required defaultValue={this.state.phone} />
														{this.state.error.indexOf('phone') > -1 ? (
															<span className="red-color">Incorrect Phone!</span>
														) : (null)}
													</Col>

													<Col className="form-group" md={4}>
														<label className="control-label">Designation: </label>
														<input type="text" className="form-control" id="designation" name="designation" onChange={this.updateChange.bind(this, "designation", false)}
															placeholder="Designation" required defaultValue={this.state.designation} />
														{this.state.error.indexOf('designation') > -1 ? (
															<span className="red-color">Incorrect Designation!</span>
														) : (null)}
													</Col>

													<Col className="form-group" md={4}>
														<label className="control-label">Status: </label>
														<select className="form-control" id="status" name="status" onChange={this.updateChange.bind(this, "status", false)} required value={this.state.status}>
															<option value="" disabled>Select Status</option>
															<option value="Active">Active</option>
															<option value="Terminated">Deactivated</option>
														</select>
														{this.state.error.indexOf('status') > -1 ? (
															<span className="red-color">Incorrect Status!</span>
														) : (null)}
													</Col>

												</form>
											</Col>
										</Row>
									</Modal.Body>
									<Modal.Footer className="footer-center">
										<Button className="btn btn-success" onClick={this.addMember.bind(this)}>Save</Button>
										<Button className="btn btn-danger" onClick={this.hideAddMemberModal.bind(this)}>Cancel</Button>
									</Modal.Footer>
								</Modal>
							) : (null)}
							{this.state.deleteadmin ? (
								<Modal bsSize="large" className="add-member-modal" show={this.state.deleteadmin} onHide={this.hideRemoveMemberModal}>
									<Modal.Header>
										<Modal.Title className="modal-header-custom">
											Remove Member
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div style={{overflow:'scroll'}}>
											<table>
												<thead>
													<th className='deletemodal_th'>
													
													</th>
													<th className='deletemodal_th'>
													Employee ID
													</th>
													<th className='deletemodal_th'>
													Full Name
													</th>
													<th className='deletemodal_th'>
													Email
													</th>
													<th className='deletemodal_th'>
													Mobile
													</th>
													<th className='deletemodal_th'>
													Department
													</th>
													<th className='deletemodal_th'>
													Designation
													</th>
													<th className='deletemodal_th'>
													Status
													</th>
												</thead>
												<tbody>
													{this.state.members && this.state.members.map((item,id)=>{
														return (
															<>
															<tr> 
																<td className='deletemodal_td'>
																	<CheckBox checked={this.state.removeadminids.includes(item.employeeId)} onClick={()=>{this.toggleAdminId(item.employeeId)}}/>
																</td>
																<td className='deletemodal_td'>
																	{item.employeeId}
																</td>
																<td className='deletemodal_td'>
																	{item.fullName}
																</td>
																<td className='deletemodal_td'>
																	{item.email}
																</td>
																<td className='deletemodal_td'>
																	{item.mobileNo}
																</td>
																<td className='deletemodal_td'>
																	{item.designationAtOrganisation}
																</td>
															</tr>	
															</>
														)
													})}
												</tbody>
											</table>
										</div>
									</Modal.Body>
									<Modal.Footer className="footer-center">
										<Button className="btn btn-success" onClick={this.Removemember.bind(this)}>Delete</Button>
										<Button className="btn btn-danger" onClick={this.hideRemoveMemberModal.bind(this)}>Cancel</Button>
									</Modal.Footer>
								</Modal>
							) : (null)}
						</Tab>
					</Tabs>
				</Row>
				{/* {console.log(this.state.editAdminRow,'this.state.editAdminRow')
				} */}
				{this.state.editAdminModalVisibility ? (
					<AddEditMemberModal
					addMemberModalVisibility={this.state.editAdminModalVisibility}
					hideAddMemberModal={this.hideEditAdminModal.bind(this)}
					role={this.state.editAdminRow?.role || ''}
					updateChange={this.updateChangeForChangingRole.bind(this)}
					error={[]}
					addMember={this.updateMember.bind(this)}
					emp_id={this.state.editAdminRow?.employeeId || ''}
					full_name={this.state.editAdminRow?.fullName || ''}
					email={this.state.editAdminRow?.email || ''}
					gender={this.state.editAdminRow?.gender || ''}
					department={this.state.editAdminRow?.department || ''}
					phone={this.state.editAdminRow?.mobileNo || ''}
					designation={this.state.editAdminRow?.designationAtOrganisation || ''}
					offices={ []}
					cities={[]}
					accessLocations={this.state.accessLocations}
					status={this.state.editAdminRow?.status || ''}
					editMember={true}
					showCityAdminOptions={false}
					/>
				) : null}
				{this.state.showAddAdminModal ? (
					<GetParticipantModal
					Action={this.addMemberAsAdmin.bind(this)}
					HideGetParicipantModal={q=>{this.setState({showAddAdminModal:false})}}
					/>
				) : null}
					</div>)
				}}
			</OrgDataContext.Consumer>
		);
	}
}